import { setRemoteDefinitions } from '@nx/angular/mf';
import { environment } from './environments/environment';

fetch('config/app.json')
  .then((response) => response.json())
  .then((config: any) => {
    // const remotes: { name: string; entry: string }[] = [];
    config.authentication.okta.idp.idpCallbackUri = config.authentication.okta.idp.idpCallbackUri
      .toString()
      .replace('${baseUrl}', window.location.origin);

    config.authentication.loginHost =
      window.location.hostname !== 'localhost' ? window.location.origin : config.authentication.loginHost;
    //Add the baseUrl to appUrls
    for (const [key, value] of Object.entries(config.appUrls)) {
      const url: string = value.toString();
      config.appUrls[key] = !url.includes('localhost') ? window.location.origin + url + '/' : url;
      // remotes.push({
      //   name: key,
      //   entry: config.appUrls[key] + 'mf-manifest.json',
      // });
    }

    // [TODO]: remove this when authentication service is in production (ie. enableAuthenticationService is true in values.yaml)
    const isAuthServiceEnabled = localStorage.getItem('omni-isAuthServiceEnabled');
    if (isAuthServiceEnabled) {
      config.enableAuthenticationService = isAuthServiceEnabled;
    }
    environment['config'] = config;
    window['config'] = config;
    window['appUrls'] = config.appUrls;
    setRemoteDefinitions(config.appUrls);
    import('./bootstrap').catch((err) => {
      console.error(err);
    });
  });
